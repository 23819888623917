import { Link } from 'react-router-dom'
import Button from '../../elements/Button'
import { useState } from 'react'

const ListDeal = ({ deal, handleDeleteDeal }) => {
  const [deleteClickCount, setDeleteClickCount] = useState(0)

  const deleteChecker = id => {
    if (deleteClickCount === 0) {
      setDeleteClickCount(1)
    } else {
      handleDeleteDeal(id)
    }
  }

  return (
    <div className='deal-table-child'>
      <div className='deal-table-name'>{deal.name}</div>
      <div className='deal-table-description'>{deal.description}</div>
      <div className='deal-table-type'>{deal.friendly_type}</div>
      <div className='deal-table-controls'>
        <Link to={`/edit/${deal._id}`} className='button'>
          Edit{' '}
        </Link>{' '}
        <Button className='cancel-link' text={deleteClickCount === 0 ? 'Delete' : 'Are you sure?'} action={() => deleteChecker(deal._id)} />
      </div>
    </div>
  )
}

export default ListDeal
