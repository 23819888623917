import React from 'react'

const Header = ({ title, link }) => {
  return (
    <header>
      <h1>{title}</h1>
      {link && link}
    </header>
  )
}

export default Header
