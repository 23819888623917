import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as DeleteIcon } from './bin.svg'

const Product = ({ product, deleteIcon, handleProductClick, inDeal }) => {
  return (
    <div className={`product-list-item ${deleteIcon ? 'removing' : 'adding'} ${inDeal ? 'in-deal' : ''}`} onClick={() => handleProductClick(product)}>
      {product.image && <img src={product.image} alt={product.title} />}
      <div>
        <p>{product.title}</p>
        {product?.price && <p className='product-price'>{product.price}0</p>}

        <div className='product-tags-container'>{product?.tags && product.tags.map(tag => <span>{tag}</span>)}</div>
      </div>
      {deleteIcon ? <DeleteIcon /> : <Plus />}
    </div>
  )
}

Product.defaultProps = {
  delete: false,
}

export default Product
