import { useState, useEffect } from 'react'

const MultibuyCalculator = ({ stackingLimit, stackingDiscount }) => {
  const [multibuyText, setMultibuyText] = useState([])

  useEffect(() => {
    var discountText = []
    var percentage = Math.trunc(stackingDiscount * 100 * 2) / 2

    for (var i = 2; i <= stackingLimit; i++) {
      discountText.push(`Buy ${i}, get ${percentage * i}% off`)
    }

    setMultibuyText(discountText)
  }, [stackingLimit, stackingDiscount])

  return (
    <div className='multibuy-tags'>
      {multibuyText.map(text => (
        <span key={text}>{text}</span>
      ))}
    </div>
  )
}

export default MultibuyCalculator
