// fetch data
const fetchApi = async data => {
  // fetch url
  const fetchUrl = `/api${data.queryString}`
  // set up body of query
  const queryBody = {
    query: data.query,
  }
  // if prducts_search, add that to the body
  if (data.searchTerm) queryBody.searchTerm = data.searchTerm
  // set up fetch parameters
  const fetchArgs = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(queryBody),
  }
  // do the fetch and return the results
  const fetchResults = await fetch(fetchUrl, fetchArgs)
  const fetchJson = await fetchResults.json()
  return fetchJson
}

export { fetchApi }
