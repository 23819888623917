const Fieldset = ({ displayName, fieldId, fieldType, fieldMin, fieldMax, fieldHelp, value, step, changeEvent }) => {
  return (
    <fieldset>
      <label htmlFor={fieldId}>{displayName}</label>
      <input
        type={fieldType}
        id={fieldId}
        min={fieldMin}
        max={fieldMax}
        value={value}
        step={step}
        onChange={e => changeEvent(e.target.id, e.target.value)}
      />
      {fieldHelp && <p className='help-text'>{fieldHelp}</p>}
    </fieldset>
  )
}

export default Fieldset
