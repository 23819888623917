import Header from '../elements/Header'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import ListContainer from './new-deal/ListContainer'
import DealDetailsForm from './new-deal/DealDetailsForm'
import Button from '../elements/Button'
import { fetchDatabase } from '../../utilities/fetchDatabase'
import { useHistory, useParams } from 'react-router-dom'

const NewDeal = ({ existingDeal, loading, toggleLoading, queryString }) => {
  // create state for new deal
  const [newDeal, setNewDeal] = useState({
    name: '',
    description: '',
    product_count: 2,
    discount: 1,
    type: 'bogof',
    friendly_type: 'Buy 1 get 1 free',
    stacking_limit: 0,
    targeting: 'group',
    products: [],
    spend_discounts: [],
  })

  // one off state for existing spend discounts
  const [existingSpendDiscounts, setExistingSpendDiscounts] = useState()

  // get existing deal if set
  const { id } = useParams()
  useEffect(() => {
    if (existingDeal) {
      async function fetchExistingDeal() {
        const response = await fetchDatabase({}, 'GET', `/database/${id}/?${queryString}`)
        setNewDeal(response)
        response?.spend_discounts && setExistingSpendDiscounts([...response.spend_discounts])
      }
      fetchExistingDeal()
    }
  }, [existingDeal, id, queryString])

  // set ready to true when deal contains miniumum required data
  const [dealReady, setDealReady] = useState(false)

  // allows save when dealReady is true
  useEffect(() => {
    if (((newDeal.products && newDeal.products.length > 0) || newDeal.type === 'spend') && newDeal.name !== '' && newDeal.description !== '') {
      setDealReady(true)
    } else {
      setDealReady(false)
    }
  }, [newDeal])

  // updates
  function handleInputChange(input, value) {
    switch (input) {
      case 'name':
      case 'description':
        setNewDeal({
          ...newDeal,
          [input]: value,
        })
        break
      case 'type':
        handleDealTypes(value)
        break
      case 'stacking_discount':
        handleDealTypes('Multibuy', null, value / 100, null)
        break
      case 'stacking_limit':
        handleDealTypes('Multibuy', parseInt(value), null, null)
        break
      case 'spend_discounts':
        handleDealTypes('Spend X get Y', null, null, value)
        break
      default:
        console.error(`No behaviour set for ${input}`)
        break
    }
  }

  // set parameters for different deal types
  function handleDealTypes(type, stacking_limit, stacking_discount, spend_discounts) {
    // Changes to make when type changes
    const dealOptions = {
      'Buy 1 get 1 free': { product_count: 2, discount: 1, type: 'bogof', friendly_type: type, targeting: 'group' },
      '3 for 2': { product_count: 3, discount: 1, type: 'bogof', friendly_type: type, targeting: 'group' },
      'Buy 1 get 1 half price': { product_count: 2, discount: 0.5, type: 'bogof', friendly_type: type, targeting: 'group' },
      Multibuy: {
        product_count: 2,
        stacking_limit: stacking_limit || newDeal.stacking_limit,
        discount: stacking_discount || newDeal.discount,
        type: 'stacking',
        friendly_type: type,
        targeting: 'single',
      },
      'Spend X get Y': { product_count: 0, type: 'spend', friendly_type: type, spend_discounts: spend_discounts || newDeal.spend_discounts },
    }

    const dealOptionUpdate = dealOptions[type]
    setNewDeal({ ...newDeal, ...dealOptionUpdate })
  }

  // add products to newDeal
  function handleAddProduct(product, filterList) {
    if (Array.isArray(product)) {
      const newProducts = product.filter(prod => (filterList.find(existingProduct => existingProduct.id === prod.id) ? false : true))
      setNewDeal({
        ...newDeal,
        products: [...newDeal.products, ...newProducts],
      })
    } else {
      setNewDeal({
        ...newDeal,
        products: [...newDeal.products, product],
      })
    }
  }

  useEffect(() => {
    console.log(newDeal)
  }, [newDeal])

  // remove products from newDeal
  function handleRemoveProduct(product) {
    const updatedProducts = newDeal.products.filter(existingProduct => existingProduct !== product)
    setNewDeal({
      ...newDeal,
      products: updatedProducts,
    })
  }

  let history = useHistory()

  async function saveDeal() {
    toggleLoading(true)
    if (existingDeal) {
      const response = await fetchDatabase(newDeal, 'PUT', `/database/${id}/?${queryString}`)
      if (!response.error) {
        toggleLoading(false)
        history.push('/')
      } else {
        alert(response.error)
        toggleLoading(false)
      }
    } else {
      const response = await fetchDatabase(newDeal, 'POST', `/database/?${queryString}`)
      if (!response.error) {
        toggleLoading(false)
        history.push('/')
      } else {
        alert(response.error)
        toggleLoading(false)
      }
    }
  }

  return (
    <>
      <Header
        title={existingDeal ? 'Edit deal' : 'Create new deal'}
        link={<Button text='Save' className={`button save-button ${dealReady ? '' : 'disabled'} ${loading ? 'loading' : ''}`} action={saveDeal} />}
      />
      <main>
        <DealDetailsForm
          dealType={newDeal.type}
          handleInputChange={handleInputChange}
          stackingLimit={newDeal.stacking_limit}
          stackingDiscount={newDeal.discount}
          currentDeal={newDeal}
          existingSpendDiscounts={existingSpendDiscounts}
        />
        <ListContainer handleAddProduct={handleAddProduct} handleRemoveProduct={handleRemoveProduct} currentDeal={newDeal} queryString={queryString} />

        <Link to='/' className='cancel-link'>
          Cancel and return to deals
        </Link>
      </main>
    </>
  )
}

export default NewDeal
