// fetch data
const fetchDatabase = async (data, method, url) => {
  // set up body of query
  const queryBody = data

  // set up fetch parameters
  const fetchArgs = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  if (method !== 'GET') fetchArgs.body = JSON.stringify(queryBody)

  // do the fetch and return the results
  const fetchResults = await fetch(url, fetchArgs)
  const fetchJson = await fetchResults.json()
  return fetchJson
}

export { fetchDatabase }
