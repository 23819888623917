import React from 'react'

const Button = ({ text, className, action }) => {
  return (
    <button className={className} onClick={action}>
      {text}
    </button>
  )
}

export default Button
