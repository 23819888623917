import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Deals from './components/pages/Deals'
import NewDeal from './components/pages/NewDeal'
import { ReactComponent as Spinner } from './components/elements/spinner.svg'

function App() {
  // loading status
  const [loading, setLoading] = useState(false)
  const toggleLoading = setting => {
    setLoading(setting)
  }
  // setup HMAC validation
  const [queryString, setQueryString] = useState(null)
  const updateQueryString = query => {
    setQueryString(query)
  }

  return (
    <Router>
      <div>
        <div className={`loading-div ${loading ? 'loading' : ''}`}>
          <Spinner />
        </div>
        <Switch>
          <Route path='/new-deal'>
            <NewDeal existingDeal={false} loading={loading} toggleLoading={toggleLoading} queryString={queryString} />
          </Route>
          <Route path='/edit/:id'>
            <NewDeal existingDeal={true} loading={loading} toggleLoading={toggleLoading} queryString={queryString} />
          </Route>
          <Route path='/'>
            <Deals toggleLoading={toggleLoading} queryString={queryString} setQueryString={updateQueryString} />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
