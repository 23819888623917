import { useState, useEffect } from 'react'
import Fieldset from './Fieldset'

const SpendXRepeater = ({ changeEvent, startingData }) => {
  // generate inputs from a spend discounts state
  const [spendDiscounts, setSpendDiscounts] = useState([
    {
      spend: '',
      discount: '',
    },
  ])

  // set initial data
  useEffect(() => {
    if (startingData?.length > 0) {
      let clonedDiscounts = [...JSON.parse(JSON.stringify(startingData))]
      let doMathDiscounts = clonedDiscounts.map(discount => {
        let updateDiscount = Object.assign({}, discount)
        updateDiscount.discount = updateDiscount.discount * 100
        return updateDiscount
      })
      setSpendDiscounts([...doMathDiscounts])
    }
  }, [startingData])

  // when spend discounts changes, organise the data and pass it up to the change event
  useEffect(() => {
    let clonedDiscounts = [...JSON.parse(JSON.stringify(spendDiscounts))]
    let sortedDiscounts = clonedDiscounts.sort((a, b) => {
      const spendA = Number(a.spend)
      const spendB = Number(b.spend)
      if (spendA < spendB) {
        return -1
      }
      if (spendA > spendB) {
        return 1
      }

      // names must be equal
      return 0
    })

    sortedDiscounts = sortedDiscounts.map(discount => {
      let updateDiscount = Object.assign({}, discount)
      if (updateDiscount.spend === '') {
        updateDiscount.spend = 0
      }
      if (updateDiscount.discount === '') {
        updateDiscount.discount = 0
      }
      updateDiscount.discount = updateDiscount.discount / 100
      return updateDiscount
    })

    changeEvent('spend_discounts', sortedDiscounts)
  }, [spendDiscounts])

  // handle input change
  const handleInputChange = (target, value) => {
    let key = target.split('_')[0]
    let spendIndex = target.split('_')[1]
    // update spendDiscounts with new value and set
    setSpendDiscounts([
      ...spendDiscounts.map((discount, index) => {
        if (index === Number(spendIndex)) {
          let updateDiscount = discount
          updateDiscount[key] = Number(value)
          return updateDiscount
        }
        return discount
      }),
    ])
  }

  // add discount
  const addDiscount = e => {
    e.preventDefault()
    setSpendDiscounts([...spendDiscounts, { spend: '', discount: '' }])
  }

  return (
    <div className='spend-discounts-inputs'>
      {spendDiscounts.map((discount, index) => (
        <div className='spend-discounts-pair' key={index}>
          <Fieldset displayName='Threshold in £/€' fieldId={`spend_${index}`} fieldType='number' fieldMin='1' step='1' value={discount.spend} changeEvent={handleInputChange} />
          <Fieldset displayName='Discount in %' fieldId={`discount_${index}`} fieldType='number' fieldMin='1' fieldMax='100' step='1' value={discount.discount} changeEvent={handleInputChange} />
        </div>
      ))}
      <button className='button' onClick={addDiscount}>
        Add tier
      </button>
    </div>
  )
}

export default SpendXRepeater
