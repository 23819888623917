import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Header from '../elements/Header'
import DealsHeader from './deals/DealsHeader'
import ListDeal from './deals/ListDeal'
import { fetchDatabase } from '../../utilities/fetchDatabase'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Deals = ({ toggleLoading, queryString, setQueryString }) => {
  // list of deals
  const [dealsList, setDealsList] = useState([])

  // query params (HMAC auth)
  const query = useQuery().toString()
  // set other deal products
  useEffect(() => {
    if (queryString === null) {
      setQueryString(query)
    }
  })

  useEffect(() => {
    async function fetchAllDeals() {
      if (queryString) {
        const response = await fetchDatabase({}, 'GET', `/database/?${queryString}`)
        setDealsList(response)
      }
    }
    fetchAllDeals()
  }, [queryString])

  const handleDeleteDeal = id => {
    async function deleteDeal() {
      toggleLoading(true)
      await fetchDatabase({}, 'DELETE', `/database/${id}/?${queryString}`)
      setDealsList(dealsList.filter(deal => deal._id !== id))
      toggleLoading(false)
    }
    deleteDeal()
  }

  return (
    <>
      <Header
        title='Deals'
        link={
          <Link to='/new-deal' className='button'>
            New deal
          </Link>
        }
      />
      <main className='grid-container fluid'>
        <section>
          <DealsHeader />
          {dealsList.length > 0 && dealsList.map(deal => <ListDeal key={deal._id} deal={deal} handleDeleteDeal={handleDeleteDeal} />)}
        </section>
      </main>
    </>
  )
}

export default Deals
