import { ReactComponent as Plus } from './plus.svg'

const Collection = ({ collection, handleCollectionClick }) => {
  return (
    <div className='collection-list-item adding' onClick={() => handleCollectionClick()}>
      <p>
        {collection.title} ({collection.products_count} products)
      </p>
      <Plus />
    </div>
  )
}

export default Collection
