const FieldsetSelect = ({ displayName, fieldId, fieldHelp, fieldOptions, value, changeEvent }) => {
  function handleChange(e) {
    changeEvent(e.target.id, e.target.value)
  }
  return (
    <fieldset>
      <label htmlFor={fieldId}>{displayName}</label>
      <select value={value} id={fieldId} onChange={e => handleChange(e)}>
        {fieldOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {fieldHelp && <p className='help-text'>{fieldHelp}</p>}
    </fieldset>
  )
}

export default FieldsetSelect
