import React from 'react'

const DealsHeader = () => {
  return (
    <div className='deal-table-header deal-table-child'>
      <div className='deal-table-name'>Name</div>
      <div className='deal-table-description'>Description</div>
      <div className='deal-table-type'>Type</div>
      <div className='deal-table-controls'></div>
    </div>
  )
}

export default DealsHeader
