import Product from '../../elements/Product'
import { useEffect, useRef } from 'react'

const CurrentProducts = ({ products, handleRemoveProduct, headingCopy }) => {
  const productsEndRef = useRef(null)
  const initialRender = useRef(0)

  useEffect(() => {
    if (initialRender.current < 2) {
      initialRender.current++
    } else {
      const scrollToBottom = () => {
        products.length > 5 && productsEndRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
      scrollToBottom()
    }
  }, [products])

  return (
    <section className='products-list'>
      <h3>{headingCopy}</h3>
      <p className='help-text'>Click a product to remove it</p>
      <div className='products-list-container'>
        {products && products.length > 0 && products.map(product => <Product deleteIcon={true} key={product.id} handleProductClick={handleRemoveProduct} product={product} />)}
        <div ref={productsEndRef} />
      </div>
    </section>
  )
}

export default CurrentProducts
